<script>

</script>

<template>
    <div class="greetings">
        <h1 class="">Geometric</h1>
        <h1 class="">Energy</h1>
        <div class="typecol">
            <h1>Space</h1>
            <h1>Energy</h1>
            <h1>Labs</h1>
            <h1>Space</h1>
    
        </div>
        <h1 class="">Corporation</h1>
    </div>
</template>

<style scoped lang="scss">
h1 {
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;margin:unset;
}

h3 {
    font-size: 1.2rem;
}
a:hover{h1{opacity:1;}}
.typecol {display:none;
    height: 3rem;
    overflow: hidden;
    h1 {
        height: 3rem;
        line-height: 3rem !important;
        animation: roll 8s ease-in-out infinite;
    }
}

@keyframes roll {
        0% {
            top: 0rem;
        }
        33% {
            top: -1rem;
        }
        66% {
            top: -2rem;
        }
        100% {
            top: -3rem;
        }
    }
    .typecol {
        height: 1rem;
        overflow: hidden;
        h1 {
            height: 1rem;
            line-height: 1rem;
            animation: roll 5s ease infinite;
        }
    }
    .greetings h1,
    .greetings h3 {
        font-size: 1rem;
        line-height: 1rem;
        text-align: left;
    }
.greetings {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

@media (max-width: 1024px) {
    @keyframes roll {
        0% {
            top: 0rem;
        }
        33% {
            top: -1rem;
        }
        66% {
            top: -2rem;
        }
        100% {
            top: -3rem;
        }
    }
    .typecol {display:none;
        height: 1rem;
        overflow: hidden;
        h1 {
            height: 1rem;
            line-height: 1rem;
            animation: roll 5s ease infinite;
        }
    }
    .greetings h1,
    .greetings h3 {display:none;
        font-size: 1rem;
        line-height: 1rem;
        text-align: left;
    }
}
</style>
