
<template>
    <div class="hero fullheight">
        <div class="hero-content">
            <div class="constraint">
                <h1>Geometric Energy</h1>
                <div class="subheader">
                    <h2>Energy Division of Geometric Energy Corporation</h2>
                </div>
                <a href="#about-home">
                    <div class="button">Read More <img src="@/assets/arrow-right-up-354.svg" style="" alt=""></div>
                </a>
    
            </div>
        </div>
        <div class="background-vid"><video src="@/assets/energy.mp4" crossorigin="anonymous" playsinline="true" preload="true" muted="true" loop="true" tabindex="1" autoplay="true"></video></div>
    </div>
    <div class="about-home " id="about-home">
        <div class="about-home-content">
            <div class="constraint">
    
                <h1>About</h1>
                <div class="subheader">
                    <h2>Geometric Energy Corporation (GEC) is a state-of-the-art technology company committed to facilitating positive social change of humanity by commercializing next-generation technologies within the sustainable energy, space, and computing
                        industries.
                    </h2>
                </div>
                <a href="mailto:info@geometricenergy.ca">
                    <div class="button">Contact <img src="@/assets/arrow-right-up-354.svg" style="" alt=""></div>
                </a>
            </div>
        </div>
    </div>
    
    <div class="sodium fullheight" id="sodium" style="display:none!important">
        <div class="sodium-content">
            <div class="constraint">
                <div class="half">
                    <div class="sodium-block">
                        <div class="wall"></div>
                        <div class="wall2"></div>
                        <div class="wall3"></div>
                        <div class="table-item">
                            <div class="atom">11</div>
                            <div class="weight">22.99</div>
                            <div class="symbol">NA</div>
                        </div>
                    </div>
                </div>
    
                <div class="half">
    
                    <h1>Developing Innovative Energy Storage Solutions</h1>
                    <div class="subheader">
                        <h2>Non-toxic, non-explosive, cost effective, sustainable alternative to lithium-ion batteries.
                        </h2>
                    </div>
                    <a href="#publications">
                        <div class="button">PUBLICATIONS <img src="@/assets/arrow-right-up-354.svg" style="" alt=""></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    
    <div class="products fullheight" id="products"  style="display:none!important;">
        <div class="products-content">
            <div class="constraint">
                <h1>Solid State Sodium Ion Battery Product Line</h1>
                <div class="subheader">
    
                    <h2>Sodium is the sixth most abundant material in Earth's crust spanning globally. This allows for a cost effective, sustainable resource for new battery materials that support a climate conscious world. Solid-state batteries significantly
                        reduce harmful volatile responses known with traditional liquid lithium-ion batteries while maintaining a competitive energy density required for intensive applications. </h2>
                </div>
                <div class="product-boxes">
    
                    <div class="product-box">
                        <p>Consumer mobile devices including laptops, mobile phones, and tablets.</p>
                        <h2>Mobile Devices</h2>
                        <img src="@/assets/arrow-right-up-354.svg" style="" alt="">
                    </div>
    
    
    
                    <div class="product-box">
                        <p>Power electric vehicles with a non-explosive, cost effective alternative.</p>
                        <h2>Electric Vehicles</h2>
                        <img src="@/assets/arrow-right-up-354.svg" style="" alt="">
                    </div>
    
    
    
                    <div class="product-box">
                        <p>Uninterrupted power supplies and charging stations.</p>
                        <h2>Power Supplies</h2>
                        <img src="@/assets/arrow-right-up-354.svg" style="" alt="">
                    </div>
    
    
    
                </div>
    
    
    
            </div>
        </div>
    </div>
    
    <div class="lab fullheight" id="lab" style="display:none!important;">
        <div class="lab-content">
            <div class="constraint">
                <div class="half">
    
                    <h1>Advanced Ionics for Sustainable Energy Lab</h1>
                    <div class="subheader">
                        <h2>Geometric Energy is partnered with the University of Calgary Thangadurai Group for research and development of next-generation battery technologies integrating novel polymer and ceramic materials.
                        </h2>
                    </div>
                    <a href="https://www.ucalgary.ca/thangadurai-group">
                        <div class="button">Thangadurai Group <img src="@/assets/arrow-right-up-354.svg" style="" alt=""></div>
                    </a>
                </div>
                <div class="half">
                    <img class="lab-img" src="@/assets/e64c81_65a91542b8c84eb7b1cdee0a5b587350~mv2.jpg" alt="">
                </div>
    
            </div>
        </div>
    </div>
    
    
    
    <div class="publications fullheight" id="publications"  style="display:none!important;">
        <div class="publications-content">
            <div class="constraint">
                <h1>Academic Publications</h1>
                <div class="publications-boxes">
    
                    <a target="_blank" href="/2019 sintering temperature excess sodium and phosphorous nasicon_suma.pdf">
                        <div class="publications-box">
                            <img src="@/assets/ion19.png" alt="">
                        </div>
    
                    </a>
                    <a target="_blank" href="/2020 electrochemical studies on symmetric solid-state na-ion full cell using navp_sourav.pdf">
                        <div class="publications-box">
                            <img src="@/assets/ion20.png" alt="">
    
                        </div>
                    </a>
                    <a target="_blank" href="/2022 ionic conductivity_pvdf_bristi.pdf">
    
                        <div class="publications-box">
                            <img src="@/assets/ion22.png" alt="">
    
                        </div>
                    </a>
    
    
                </div>
    
    
    
            </div>
        </div>
    </div>
    
    <div class="partners fullheight" style="display:none!important;">
        <div class="constraint">
            <a target="_blank" href="https://ucalgary.ca/"><img src="@/assets/UC-vert-white.webp" alt=""></a>
            <a target="_blank" href="https://www.mitacs.ca/en/partner/geometric-energy-corporation"> <img src="@/assets/mitacs_white_0.webp" alt=""></a>
            <a target="_blank" href="https://www.nserc-crsng.gc.ca/"><img src="@/assets/NSERC_WHITE.webp" alt=""></a></div>
    </div>
</template>


<style lang="scss">
.hero {
    display: flex;
    flex-flow: wrap;
    position: relative;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
    justify-content: unset !important;
    background-size: cover;
    background-position: center;
    background-color: black;
    background-image: url(../assets/electricfast.gif);
}

.fullheight {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.hero-content {
    height: 100vh;
    position: absolute;
    width: 100vw;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    z-index: 2;
    max-width: 100%;
    margin: auto;
    padding: 4rem;
    backdrop-filter: brightness(0.5);
    .constraint {
        h1 {
            line-height: 1;
            font-weight: 600;
            margin-bottom: 1rem;
        }
        h2 {}
        .button {}
    }
    &:hover {
        backdrop-filter: brightness(0.5);
    }
}

.partners {
    padding: 2rem;
    height: auto;
    display: flex;
    flex-flow: wrap;
    margin: auto;
    max-width: 100%;
    justify-content: space-between;
    background: black;
    width: 100%;
    .constraint {
        a {
            transition: all 0.2s ease;
            &:hover {
                transform: scale(1.1);
            }
            height: auto;
            margin: auto;
            img {
                height: auto;
                width: auto;
                margin: auto;
            }
        }
        display:flex;
        flex-flow:wrap;
    }
}

.background-vid {
    height: auto;
    overflow: hidden;
    video {
        min-height: 100vh;
        min-width: 100vw;
        position: fixed;
    }
}

.constraint {
    margin: auto;
    text-align: left;
    width: 1080px;
    max-width: 100%;
}

.about-home {
    background-repeat: repeat-x;
    width: 100vw;
    min-width: 100vw;
    z-index: 2;
    background-size: contain;
    background-image: url(../assets/noiseback.svg);
    left: 0px;
    backdrop-filter: brightness(0.5);
    background-position: bottom;
}

.about-home-content {
    max-width: 100%;
    margin: auto;
    padding: 8rem 4rem;
    margin-bottom: -2px;
    h1 {
        font-weight: 600;
        font-size: 3rem;
        margin: auto;
    }
}

.sodium {
    width: 100vw;
    min-width: 100vw;
    z-index: 2;
    background: black;
    left: 0px;
}

.sodium-content {
    max-width: 100%;
    margin: auto;
    padding: 8rem 4rem;
    .constraint {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        .sodium-block {
            perspective: 600px;
            margin: auto;
            width: 300px;
            height: 300px;
            cursor: pointer;
            &:hover {
                .wall {
                    background: #ffefb1;
                    box-shadow: 0px 0px 10px #ffdd81, 0px 0px 20px #ffdd81, 0px 0px 60px #ffdd81;
                    transform: rotatey(135deg);
                }
                .wall2 {
                    transform: rotatey(135deg)translatez(300px);
                    border-color: white;
                }
                .wall3 {
                    transform: rotatey(45deg)translateZ(-300px);
                    border-color: white;
                }
                .table-item {
                    transform: rotatey(45deg);
                    .atom {
                        font-weight: 400;
                        padding: 8px;
                        left: 1rem;
                        top: 0.5rem;
                        font-size: 3rem;
                        position: absolute;
                        transform: translatez(50px)
                    }
                    .weight {
                        font-weight: 400;
                        padding: 8px;
                        right: 1rem;
                        bottom: 1.5rem;
                        position: absolute;
                        transform: translatez(50px)
                    }
                    .symbol {
                        font-weight: 600;
                        font-size: 10rem;
                        position: absolute;
                        width: 100%;
                        line-height: 300px;
                        text-shadow: 0px 0px 10px #ffdd81, 0px 0px 20px #ffdd81, 0px 0px 60px #ffdd81;
                        text-align: center;
                        transform: translatez(-50px)
                    }
                }
            }
            .wall {
                background: black;
                transition: all 0.5s ease;
                width: 300px;
                height: 300px;
                transform-origin: left;
                border: 2px solid #fff5;
                position: absolute;
                transform: rotatey(90deg);
            }
            .wall2 {
                background: black;
                transition: all 0.5s ease;
                width: 300px;
                height: 300px;
                transform-origin: left;
                position: absolute;
                border: 2px solid #fff5;
                transform: rotatey(90deg)translatez(300px);
            }
            .wall3 {
                background: black;
                transition: all 0.5s ease;
                width: 300px;
                height: 300px;
                border: 2px solid #fff5;
                transform-origin: left;
                position: absolute;
                transform: rotatey(0deg)translateZ(-300px);
            }
            .table-item {
                transition: all 0.5s ease;
                width: 300px;
                height: 300px;
                transform-origin: left;
                position: relative;
                border: 1px solid white;
                transform: rotatey(0deg);
                perspective: 600px;
                &:after {
                    content: '';
                }
                .atom {
                    transition: all 0.5s ease;
                    font-weight: 600;
                    padding: 8px;
                    left: 1.5rem;
                    top: 1rem;
                    font-size: 2rem;
                    position: absolute;
                }
                .weight {
                    transition: all 1s ease;
                    font-weight: 600;
                    padding: 8px;
                    right: 2rem;
                    bottom: 1.5rem;
                    position: absolute;
                }
                .symbol {
                    transition: all 1.5s ease;
                    font-weight: 600;
                    font-size: 8rem;
                    position: absolute;
                    width: 100%;
                    line-height: 300px;
                    text-align: center;
                }
            }
        }
        .half {
            h1 {
                font-weight: 600;
                font-size: 3em;
                line-height: 1;
                margin: auto;
                margin-bottom: 1rem;
            }
            &:nth-child(1) {
                width: auto;
                margin-bottom: 1rem;
            }
            &:nth-child(2) {
                width: 700px;
            }
        }
    }
}

.lab {
    width: 100vw;
    min-width: 100vw;
    z-index: 2;
    background: black;
    left: 0px;
}

.lab-content {
    max-width: 100%;
    margin: auto;
    padding: 8rem 4rem;
    .constraint {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        .lab-img {
            transition: all 0.2s ease;
            filter: contrast(1.2)saturate(0);
            width: 380px;
            &:hover {
                filter: contrast(1.2)saturate(1);
            }
        }
        .half {
            h1 {
                font-weight: 600;
                font-size: 3rem;
                line-height: 1;
                margin: auto;
                margin-bottom: 1rem;
            }
            &:nth-child(2) {
                width: 500px;
                margin-bottom: 1rem;
            }
            &:nth-child(1) {
                width: 500px;
            }
        }
    }
}

.subheader {
    margin: 2rem 0rem;
    margin-top: 0rem;
}

.products {
    width: 100vw;
    min-width: 100vw;
    z-index: 2;
    color: rgb(255, 255, 255) !important;
    background: #000000;
    left: 0px;
}

.products-content {
    width: 100vw;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    z-index: 2;
    max-width: 100%;
    margin: auto;
    padding: 4rem;
    transition: all 5s ease;
    .constraint {
        h1 {
            font-weight: 600;
            color: rgb(255, 255, 255);
            line-height: 3rem;
            margin-bottom: 1rem;
        }
    }
    &:hover {}
}

.product-boxes {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 2rem;
}

.product-box {
    &:nth-child(2) {}
    &:nth-child(3) {}
    width: 360px;
    height: 360px;
    background: rgb(0, 0, 0);
    box-shadow: 0px 0px 0px 1px white;
    filter: invert(0);
    transition: all 0.2s ease;
    &:hover {
        filter: invert(1);
        box-shadow: 0px 0px 0px 1px black;
    }
    p {
        font-weight: 200;
        line-height: 2rem;
        left: 1rem;
        padding-right: 2rem;
        top: 0.75rem;
        color: white;
    }
    h2 {
        font-weight: 400;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        color: white;
        font-size: 2rem;
        line-height: 2rem;
    }
    img {
        height: 32px;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
}

.publications {
    width: 100vw;
    min-width: 100vw;
    z-index: 2;
    color: rgb(255, 255, 255) !important;
    background: #000000;
    left: 0px;
}

.publications-content {
    width: 100vw;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    z-index: 2;
    max-width: 100%;
    margin: auto;
    padding: 4rem;
    transition: all 5s ease;
    .constraint {
        h1 {
            color: rgb(255, 255, 255);
            line-height: 3rem;
            font-weight: 600;
            margin-bottom: 4rem;
        }
    }
    &:hover {}
}

.publications-boxes {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 2rem;
    justify-content: space-between;
}

.publications-box {
    transition: all 0.2s ease;
    &:hover {
        transform: scale(1.1);
    }
    width: 320px;
    height: 320px;
    img {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}

@media (max-width: 1024px) {
    .publications {
        width: 100vw;
        min-width: 100vw;
        z-index: 2;
        color: rgb(255, 255, 255) !important;
        background: #000000;
        left: 0px;
    }
    .about-home {
        padding-top: 2rem;
        width: 100vw;
        min-width: 100vw;
        z-index: 2;
        background-size: cover;
        background-image: url(../assets/noiseback.svg);
        left: 0px;
        backdrop-filter: brightness(0.5);
        background-position: bottom;
    }
    .publications-content {
        width: 100vw;
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;
        z-index: 2;
        max-width: 100%;
        margin: auto;
        padding: 2rem;
        transition: all 5s ease;
        .constraint {
            h1 {
                color: rgb(255, 255, 255);
                line-height: 2rem;
                margin-bottom: 1rem;
            }
        }
        &:hover {}
    }
    .publications-boxes {
        display: flex;
        flex-flow: wrap;
        margin-bottom: 2rem;
    }
    .publications-box {
        margin-bottom: 2rem;
        width: calc(100vw - 4rem);
        height: calc(100vw - 4rem);
        img {
            pointer-events: none;
            width: 100%;
            height: 100%;
        }
    }
    .hero-content {
        padding: 2rem;
        .constraint {
            h1 {
                font-size: 2rem;
            }
        }
    }
    .products-content {
        width: 100vw;
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;
        z-index: 2;
        max-width: 100%;
        margin: auto;
        padding: 4rem;
        transition: all 5s ease;
        .constraint {
            h1 {
                color: rgb(255, 255, 255);
                line-height: 2rem;
                margin-bottom: 1rem;
            }
        }
        &:hover {}
    }
    .subheader {
        margin: 2rem 0rem;
        margin-top: 0rem;
    }
    h1 {
        line-height: 2.2rem;
        font-size: 2rem;
    }
    h2 {
        line-height: 1.5rem;
        margin-bottom: 1rem;
        font-size: 1rem;
    }
    .product-box {
        width: calc(100vw - 4rem);
        height: calc(100vw - 4rem);
        filter: invert(0);
        &:hover {
            filter: invert(1);
        }
        p {
            font-weight: 600;
            line-height: 2rem;
            left: 1rem;
            padding-right: 2rem;
            top: 0.75rem;
            color: white;
        }
        h2 {
            font-weight: 400;
            position: absolute;
            bottom: 0rem;
            left: 1rem;
            color: white;
            font-size: 2rem;
            line-height: 2rem;
        }
        img {
            height: 32px;
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
    }
    .about-content,
    .products-content,
    .sodium-content {
        padding: 2rem;
    }
    .sodium-content {
        max-width: 100%;
        margin: auto;
        padding: 8rem 2rem;
        .constraint {
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            .sodium-block {
                perspective: 600px;
                margin: auto;
                width: calc(100vw - 4rem);
                height: calc(100vw - 4rem);
                cursor: pointer;
                &:hover {
                    .wall {
                        background: #ffefb1;
                        box-shadow: 0px 0px 10px #ffdd81, 0px 0px 20px #ffdd81, 0px 0px 60px #ffdd81;
                        transform: rotatey(135deg);
                    }
                    .wall2 {
                        transform: rotatey(135deg)translatez(calc(100vw - 4rem));
                        border-color: white;
                    }
                    .wall3 {
                        transform: rotatey(45deg)translateZ(calc(-100vw + 4rem));
                        border-color: white;
                    }
                    .table-item {
                        transform: rotatey(45deg);
                        .atom {
                            font-weight: 400;
                            padding: 8px;
                            left: 1rem;
                            top: 0.5rem;
                            font-size: 3rem;
                            position: absolute;
                            transform: translatez(50px)
                        }
                        .weight {
                            font-weight: 400;
                            padding: 8px;
                            right: 1rem;
                            bottom: 1.5rem;
                            position: absolute;
                            transform: translatez(50px)
                        }
                        .symbol {
                            font-weight: 600;
                            font-size: 10rem;
                            position: absolute;
                            width: 100%;
                            line-height: calc(100vw - 4rem);
                            text-shadow: 0px 0px 10px #ffdd81, 0px 0px 20px #ffdd81, 0px 0px 60px #ffdd81;
                            text-align: center;
                            transform: translatez(-50px)
                        }
                    }
                }
                .wall {
                    background: black;
                    transition: all 0.5s ease;
                    width: calc(100vw - 4rem);
                    height: calc(100vw - 4rem);
                    transform-origin: left;
                    border: 2px solid #fff5;
                    position: absolute;
                    transform: rotatey(90deg);
                }
                .wall2 {
                    background: black;
                    transition: all 0.5s ease;
                    width: calc(100vw - 4rem);
                    height: calc(100vw - 4rem);
                    transform-origin: left;
                    position: absolute;
                    border: 2px solid #fff5;
                    transform: rotatey(90deg)translatez(calc(100vw - 4rem));
                }
                .wall3 {
                    background: black;
                    transition: all 0.5s ease;
                    width: calc(100vw - 4rem);
                    height: calc(100vw - 4rem);
                    border: 2px solid #fff5;
                    transform-origin: left;
                    position: absolute;
                    transform: rotatey(0deg)translateZ(calc(-100vw + 4rem));
                }
                .table-item {
                    transition: all 0.5s ease;
                    width: calc(100vw - 4rem);
                    height: calc(100vw - 4rem);
                    transform-origin: left;
                    position: relative;
                    border: 1px solid white;
                    transform: rotatey(0deg);
                    perspective: 600px;
                    &:after {
                        content: '';
                    }
                    .atom {
                        transition: all 0.5s ease;
                        font-weight: 600;
                        padding: 8px;
                        left: 1.5rem;
                        top: 1rem;
                        font-size: 2rem;
                        position: absolute;
                    }
                    .weight {
                        transition: all 1s ease;
                        font-weight: 600;
                        padding: 8px;
                        right: 2rem;
                        bottom: 1.5rem;
                        position: absolute;
                    }
                    .symbol {
                        transition: all 1.5s ease;
                        font-weight: 600;
                        font-size: 8rem;
                        position: absolute;
                        width: 100%;
                        line-height: calc(100vw - 4rem);
                        text-align: center;
                    }
                }
            }
            .half {
                h1 {
                    font-weight: 600;
                    font-size: 2rem;
                    margin: auto;
                    margin-bottom: 1rem;
                }
                &:nth-child(1) {
                    width: auto;
                    margin-bottom: 1rem;
                }
                &:nth-child(2) {
                    width: 700px;
                }
            }
        }
    }
    .lab {
        width: 100vw;
        min-width: 100vw;
        z-index: 2;
        background: black;
        left: 0px;
    }
    .lab-content {
        max-width: 100%;
        margin: auto;
        padding: 8rem 2rem;
        .constraint {
            display: flex;
            flex-flow: wrap-reverse;
            justify-content: space-between;
            .lab-img {
                transition: all 0.2s ease;
                filter: contrast(1.2)saturate(0);
                width: calc(100vw - 4rem);
                &:hover {
                    filter: contrast(1.2)saturate(1);
                }
            }
            .half {
                h1 {
                    font-weight: 600;
                    font-size: 2rem;
                }
                &:nth-child(2) {
                    width: calc(100vw - 4rem);
                    margin-bottom: 1rem;
                }
                &:nth-child(1) {
                    width: calc(100vw - 4rem);
                }
            }
        }
    }
    .about-home {
        margin-top: -20vh
    }
    .about-home-content {
        max-width: 100%;
        margin: auto;
        padding: 8rem 2rem;
        margin-bottom: -2px;
        h1 {
            font-weight: 600;
            font-size: 2rem;
            margin: auto;
            margin-bottom: 1rem;
        }
    }
    .hero {
        background-attachment: fixed;
        height: 120vh;
        background-image: url(../assets/electricmobile.gif);
    }
    .background-vid {
        display: none;
    }
    .partners {
        padding: 2rem;
        height: auto;
        display: flex;
        margin: auto;
        max-width: 100%;
        justify-content: space-between;
        background: black;
        width: 100%;
        .constraint {
            a {
                margin: 2rem auto;
            }
            flex-flow: column;
        }
    }
}
</style>
